import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import Seo from "../components/seo"

const Redirect = ({ intl }) => {
  return (
    <Seo
      title={`${intl.formatMessage({ id: "title" })}`}
      lang={`${intl.locale}`}
      description={`${intl.formatMessage({ id: "description" })}`}
    />
  )
}

export default injectIntl(Redirect)
